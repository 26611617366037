import React from "react"
import Header from "./header";
import { domain, stripString } from "../utils";
import TabbedList from "./wrappers/tabbed-list";
import TabbedItem from "./wrappers/tabbed-item";
import ServiceCard from "./service-card";
import classNames from "classnames";

const TabbedServices = ({ component, language, shouldNotLazyLoad, imageBorderRadiusForServices, sectionBackgroundColor, websiteFontColor }) => {
  return (
    <section className={classNames("section tabbed")} id={stripString(component.header.anchorText && component.header.anchorText || '')} style={{backgroundColor: sectionBackgroundColor}}>
        {component.header && (
            <Header heading={component.header.heading} supheading={component.header.supheading}/>
        )}
        
        <div className="container">
            <TabbedList>
                {component.services.map((service, index) => (
                    <TabbedItem 
                      id={( index + 1 < 10 ? "0" : "")+(index+1)} 
                      group="highlights" 
                      heading={service?.heading} 
                      initiallyChecked={index === 0}
                      imageBorderRadiusForServices={imageBorderRadiusForServices}
                    >
                        <ServiceCard 
                          service={service} 
                          showHeading={false} 
                          language={language} 
                          shouldNotLazyLoad={shouldNotLazyLoad} 
                          imageBorderRadiusForServices={imageBorderRadiusForServices}
                          websiteFontColor={websiteFontColor}
                        />
                    </TabbedItem>
                ))}
            </TabbedList>
        </div>
    </section>
  );
}

export default TabbedServices

